import CryptoJS from "crypto-js";
import { COOKIE_KEYS } from "shared/constants/cookieKeys";
import {
  getCookie,
  removeCookie,
  setCookie,
} from "shared/lib/legacyUtils/cookie";
import type { KeepLoginPayload } from "../api/keepLoginApi";

export const EMPTY_LOGIN_SERVICE_ID = "off";
const EXPIRE_DATES = 180; // 3개월

const DEFAULT_LOGIN_PAYLOAD = {
  id: "",
  loginServiceId: EMPTY_LOGIN_SERVICE_ID,
};

/** 유효한 loginServiceId인지 확인합니다. */
export const validateLoginServiceId = (
  loginServiceId: KeepLoginPayload["loginServiceId"]
) => {
  return loginServiceId && loginServiceId !== EMPTY_LOGIN_SERVICE_ID;
};

/** 유효한 자동로그인 페이로드인지 확인합니다. */
export function validateKeepLoginPayload({
  loginServiceId,
  id,
}: KeepLoginPayload) {
  return validateLoginServiceId(loginServiceId) && Boolean(id);
}

/** 자동로그인 상태인지 확인합니다. */
export function isKeepLogin() {
  const { loginServiceId, id } = getKeepLoginCookie();
  return validateKeepLoginPayload({ loginServiceId, id });
}

//   ==== 로컬 저장소 ====
export function getKeepLoginCookie(): KeepLoginPayload {
  try {
    const decrypted = CryptoJS.AES.decrypt(
      getCookie(COOKIE_KEYS.KEEP_LOGIN),
      process.env.REACT_APP_CRYPTO_KEY!
    );
    return (
      JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)) ?? DEFAULT_LOGIN_PAYLOAD
    );
  } catch (e) {
    return DEFAULT_LOGIN_PAYLOAD;
  }
}

export function setKeepLoginCookie({ loginServiceId, id }: KeepLoginPayload) {
  const expires = new Date();
  expires.setDate(expires.getDate() + EXPIRE_DATES);

  const payload = { loginServiceId, id };
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(payload),
    process.env.REACT_APP_CRYPTO_KEY!
  ).toString();

  setCookie(COOKIE_KEYS.KEEP_LOGIN, encrypted, { path: "/", expires });
}

export function removeKeepLoginCookie() {
  removeCookie(COOKIE_KEYS.KEEP_LOGIN, { path: "/" });
}
