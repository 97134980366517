import { getTokenTime } from "entities/user-info";
import moment from "moment";
import { showAssert } from "./showAssert";

function convertTime(time: number) {
  const MINUTES = 60;
  const _time = Math.round(time);
  const minString =
    _time > MINUTES ? `(${Math.round((_time / MINUTES) * 10) / 10}분)` : "";
  return `${_time}초 ${minString}`;
}

export function showTokenInfo() {
  showAssert.group("토큰 정보", () => {
    const accessInfo = getTokenTime("accessToken");
    const refreshInfo = getTokenTime("refreshToken");

    if (accessInfo && refreshInfo) {
      const accessTime = convertTime(accessInfo.exp - accessInfo.currentTime);
      const refreshTime = convertTime(
        refreshInfo.exp - refreshInfo.currentTime
      );
      console.log(moment().format("YYYY-MM-DD HH:mm:ss"));
      console.log(`🔑 access: ${accessTime}`);
      console.log(`🔐 refresh: ${refreshTime}`);
    } else {
      console.log("없음");
    }
  });
}
