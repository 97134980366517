import type { KeepLoginResponse } from "features/auth/keep-login";
import { addTokensToCookies } from "features/auth/lib/addTokensToCookies";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import { COOKIE_KEYS } from "shared/constants/cookieKeys";
import { getCookie } from "shared/lib/legacyUtils/cookie";
import type { APIResponse } from "shared/types/server";

export const refreshTokenApi = {
  /** Refresh Token으로 새로운 토큰 발급
   * @see https://sbridge.atlassian.net/wiki/x/LIDIBQ
   */
  refreshToken: async () => {
    const {
      data: { data: response },
    } = await instance.post<APIResponse<KeepLoginResponse>>(api.users.refresh, {
      refreshToken: getCookie(COOKIE_KEYS.REFRESH_TOKEN),
    });

    if (response.responseType === "SUCCESS") {
      addTokensToCookies(response.accessToken, response.refreshToken);
    }

    return response;
  },
};
