/* eslint-disable no-console */
import { isProduction } from "./production";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function showAssert(message: any) {
  if (isProduction()) return;
  typeof message === "string"
    ? console.log(`%c${message}`, "color:blue")
    : console.log(message);
}

function showAssertGroup(title: string, callback: VoidFunction) {
  if (isProduction()) return;
  console.group(title);
  callback?.();
  console.groupEnd();
}

showAssert.group = showAssertGroup;
