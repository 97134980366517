import type { StoreStateTypes } from "@store/Store.types";
import {
  logIn,
  logOut,
  updateNavigationPath,
} from "@store/features/user/userSlice";
import type { UserInfo } from "entities/user-info";
import { getTokenTime, getUserInfoFromAccessToken } from "entities/user-info";
import { isKeepLogin, keepLoginApi } from "features/auth/keep-login";
import { useSignOut } from "features/auth/sign-out";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { COOKIE_KEYS } from "shared/constants/cookieKeys";
import { getCookie, removeCookie } from "shared/lib/legacyUtils/cookie";
import { showAlert } from "shared/lib/showAlert";
import { useAppDispatch, useAppSelector } from "./appStore";

type UseRestrictedAccessProps = {
  /** 제한된 경로 목록 */
  restrictedPaths: string[];
};

/**
 * 특정 경로에 대한 접근 제어 및 로그인 상태 확인을 관리하는 커스텀 훅입니다.
 */
export const useRestrictedAccess = ({
  restrictedPaths,
}: UseRestrictedAccessProps) => {
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector(
    (state: StoreStateTypes) => state.user.isLoggedIn
  );

  useEffect(() => {
    const currentPathname = window.location.pathname;
    const isRestrictedPath = restrictedPaths.some(path =>
      currentPathname?.includes(path)
    );

    // 로그인이 필요한 페이지에 접근하려는 경우
    if (isRestrictedPath && !isLoggedIn) {
      // 비회원 주문 조회 페이지는 예외 처리
      const isGuestOrderPage = currentPathname?.includes("/myshop/orders");
      const accessToken = getCookie("accessToken");
      if (isGuestOrderPage && accessToken) {
        const token: any = jwtDecode(accessToken);
        if (token?.role === "guest") return; // "비회원"은 허용
      }

      // 로그인 팝업 표시 후 홈페이지로 리다이렉트
      showAlert("로그인 후 이용가능합니다.").then(result => {
        if (result.isConfirmed) {
          navigate("/signin");
        }
      });
    }
  }, [isLoggedIn, navigate, restrictedPaths]);
};

/**
 * 로그인 상태를 관리하고 동기화하는 훅입니다.
 *
 * - 액세스 토큰 유무에 따라 사용자의 로그인 상태를 업데이트합니다.
 * - 토큰이 없거나 "비회원"으로 로그인된 상태일 경우 로그아웃 처리를 합니다.
 */
export const useSyncAuthState = () => {
  const dispatch = useAppDispatch();
  const { isLoggedIn, userId } = useAppSelector(state => state.user);
  const { mutate: signOut } = useSignOut();

  // 토큰 만료 시 쿠키 삭제
  useEffect(() => {
    const refreshTokenTime = getTokenTime("refreshToken");

    if (refreshTokenTime) {
      const diff = refreshTokenTime.exp - refreshTokenTime.currentTime;
      if (diff < 0) {
        removeCookie(COOKIE_KEYS.REFRESH_TOKEN, { path: "/" });
      }
    }
  }, []);

  // 로그인 상태 동기화
  useEffect(() => {
    const currentPathname = window.location.pathname;
    const userInfo = getUserInfoFromAccessToken();
    const hasAccessToken = userInfo;

    const isGuest = userInfo?.id.includes("비회원");
    // 비회원 계정으로 로그인된 상태라면 로그아웃 처리
    if (isGuest) {
      dispatch(logOut());
      return;
    }

    hasAccessToken ? handleLoggedInUser(userInfo) : handleLoggedOutUser();

    /** 로그인 상태인 사용자 처리 로직 */
    function handleLoggedInUser(userInfo: UserInfo) {
      const needsLogin = !isLoggedIn && !isGuest;
      const isUserIdMismatch = userInfo.id !== userId;

      // 사용자가 로그인되어 있지 않은 경우 로그인 상태로 업데이트
      if (needsLogin || isUserIdMismatch) {
        dispatch(logIn({ userId: userInfo.id, role: userInfo.role }));
      }
    }

    /** 로그아웃 상태인 사용자 처리 로직 */
    async function handleLoggedOutUser() {
      if (isKeepLogin()) {
        const res = await keepLoginApi.keepLogin();
        if (typeof res === "object" && res.responseType === "SUCCESS") {
          const userInfo = getUserInfoFromAccessToken();
          userInfo && handleLoggedInUser(userInfo);
        }
      } else if (isLoggedIn) {
        signOut();
      }

      if (currentPathname !== "/signin/dormant") {
        localStorage.removeItem("userId");
      }
    }
  }, [dispatch, isLoggedIn, signOut, userId]);
};

/** 페이지 이동 히스토리를 업데이트하는 훅입니다. */
export const usePageHistoryUpdate = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { navigationPath } = useAppSelector(state => state.user);

  useEffect(() => {
    const currentPathname = `${location.pathname}${location.search}`;

    // 새로고침 시에는 history가 저장되지 않도록 설정
    if (currentPathname === navigationPath?.to) return;

    dispatch(
      updateNavigationPath({ from: navigationPath?.to, to: currentPathname })
    );
  }, [dispatch, location.pathname, location.search, navigationPath?.to]);
};
