import { addTokensToCookies } from "features/auth/lib/addTokensToCookies";
import type { LoginServiceId, SignInResponse } from "features/auth/sign-in";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import type { APIResponse } from "shared/types/server";
import { getKeepLoginCookie, isKeepLogin } from "../lib/keepLogin";

export type KeepLoginPayload = {
  loginServiceId: LoginServiceId;
  id: string;
};

export type KeepLoginResponse = Pick<
  SignInResponse,
  "responseType" | "accessToken" | "refreshToken"
>;

export const keepLoginApi = {
  /**
   * 로그인 유지 토큰 발급
   * @see https://sbridge.atlassian.net/wiki/x/AQAEEg
   */
  keepLogin: async (payload: KeepLoginPayload = getKeepLoginCookie()) => {
    if (!isKeepLogin()) return "off";

    const {
      data: { data: response },
    } = await instance.post<APIResponse<KeepLoginResponse>>(
      api.users.keepLogin,
      payload
    );

    if (response.responseType === "SUCCESS") {
      addTokensToCookies(response.accessToken, response.refreshToken);
    }

    return response;
  },
};
