import type { AxiosRequestConfig } from "axios";
import moment from "moment";
import { ERROR_CODE } from "shared/constants/errorCode";

const sourceRequest: Record<string, Date> = {};

/** 보호된 URL인지 확인하는 함수 */
const isProtectedUrl = (
  protectedUrls: string[],
  currentUrl?: string
): boolean => protectedUrls.some(url => url === currentUrl);

/** 요청 키 생성 함수 */
const createRequestKey = (url: string, data: unknown) =>
  `${url}$${JSON.stringify(data)}`;

/** 중복 요청 여부 확인 함수 */
const isDuplicateRequest = (
  previousRequestTime: Date | undefined,
  rejectSecond: number
) => {
  const now = new Date();

  // 요청 간 시간 차이 계산
  const diffInSeconds = previousRequestTime
    ? moment(now).diff(previousRequestTime, "second")
    : Infinity;

  return Boolean(previousRequestTime) && diffInSeconds < rejectSecond;
};

type HandleDuplicatedRequestProps = {
  protectedUrls: string[];
  config: AxiosRequestConfig<unknown>;
  rejectSecond?: number;
};

/**
 * 중복 요청 처리
 * @see https://velog.io/@1998yuki0331/axios를-사용해서-중복된-http-요청-막기
 */
export async function handleDuplicatedRequest({
  protectedUrls,
  config,
  rejectSecond = 10,
}: HandleDuplicatedRequestProps) {
  if (!config.url) return;
  if (!isProtectedUrl(protectedUrls, config.url)) return;

  const key = createRequestKey(config.url, config.data);
  const previousRequestTime = sourceRequest[key];

  if (isDuplicateRequest(previousRequestTime, rejectSecond)) {
    throw new Error(ERROR_CODE.common.DuplicateRequest);
  }

  sourceRequest[key] = new Date();
}
