import { getUserInfoFromToken } from "entities/user-info";
import { COOKIE_KEYS } from "shared/constants/cookieKeys";
import { generateTmpCartId, validateTmpCartId } from "shared/lib";
import { getCookie, setCookie } from "shared/lib/legacyUtils/cookie";

type AuthAndCartHeaders = {
  Authorization: string;
  "Cart-Id": string;
};

/** 비회원 헤더를 반환합니다. */
const getGuestHeaders = (): AuthAndCartHeaders => {
  const hasValidTempCartId = validateTmpCartId();

  const tempCartId = hasValidTempCartId
    ? getCookie(COOKIE_KEYS.TEMP_CART_ID)
    : generateTmpCartId();

  if (!hasValidTempCartId) {
    setCookie(COOKIE_KEYS.TEMP_CART_ID, tempCartId, { path: "/" });
  }

  return {
    Authorization: "",
    "Cart-Id": tempCartId,
  };
};

/** 인증 헤더를 반환합니다. */
const getAuthHeaders = (
  accessToken: string,
  userId: string
): AuthAndCartHeaders => {
  setCookie(COOKIE_KEYS.TEMP_CART_ID, userId, { path: "/" });

  return {
    Authorization: `Bearer ${accessToken}`,
    "Cart-Id": userId,
  };
};

/** 요청 헤더를 반환합니다. */
export function getAxiosRequestHeaders() {
  const accessToken = getCookie(COOKIE_KEYS.ACCESS_TOKEN);
  const userInfo = getUserInfoFromToken(accessToken);
  const userId = userInfo.id;

  if (!accessToken || userId.includes("비회원")) {
    return getGuestHeaders();
  }

  return getAuthHeaders(accessToken, userId);
}
