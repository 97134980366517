import { COOKIE_KEYS } from "shared/constants/cookieKeys";
import { showAlert } from "shared/lib";
import { removeCookie } from "shared/lib/legacyUtils/cookie";
import { removeLocalDataForSignOut } from "./removeLocalDataForSignOut";

export const handleSignOutAndRedirect = async (message = "") => {
  // 자동 로그아웃 시에는 push 알림 수신 되도록 하기 위해 로그아웃 서버요청 X
  // await signOutApi.signout(getSignOutPayload());
  removeLocalDataForSignOut();
  removeCookie(COOKIE_KEYS.TEMP_CART_ID, { path: "/" });

  const reason = message ? ` (${message})` : "";

  showAlert.redirect("/signin", `로그인 정보가 만료되었습니다. ${reason}`);
};
